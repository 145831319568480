import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'new-york',
  title: 'New York',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          'Walk-ins are welcome from 11 a.m. to 5 p.m. on Monday, Wednesday, and Friday.',
          'Open Tuesday and Thursday by appointment.',
          '355 West Broadway, #4',
          'New York, NY 10013',
          {render: () => (
            <a className='phone' href='tel:6464549696'>646.454.9696</a>
          )},
          {render: () => (
            <a className='email' href='mailto:newyork@spacetheory.com'>newyork@spacetheory.com</a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('new-york/1.jpg')}],
    }
  ],
  structuredData: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Space Theory",
    "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
    "url": "https://www.spacetheory.com/",
    "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "hasMap": "https://maps.app.goo.gl/P8TgkPeh5MJ9Ni239",
    "branchOf": {
      "@type": "Organization",
      "name": "Space Theory",
      "url": "https://www.spacetheory.com"
    },
    "location": [
      {
        "@type": "LocalBusiness",
        "@id": "https://www.spacetheory.com/page/new-york",
        "name": "NY Location",
        "url": "https://www.spacetheory.com/page/new-york",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "355 West Broadway, #4",
          "addressLocality": "New York",
          "addressRegion": "NY",
          "postalCode": "10013",
          "addressCountry": "United States"
        },
        "telephone": "+1-646-454-9696",
        "openingHours": "M, T, Th by Appt., Wed and Fri 011:00-17:00",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "40.723211478648125",
          "longitude": "-74.00313749135786"
        }
      }
    ],
    "sameAs": [
      "https://www.facebook.com/spacetheorykitchens/",
      "https://www.instagram.com/space.theory/"
    ]
  }
};
